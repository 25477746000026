import assets from '../common/assets';
import { getFileList, setFileList } from './api';
import { encryptStream, decryptStream } from './ece';
import { arrayToB64, b64ToArray, streamToArrayBuffer } from './utils';
import { blobStream } from './streams';

const textEncoder = new TextEncoder();
const textDecoder = new TextDecoder();
const anonId = arrayToB64(crypto.getRandomValues(new Uint8Array(16)));

async function hashId(id) {
  const d = new Date();
  const month = d.getUTCMonth();
  const year = d.getUTCFullYear();
  const encoded = textEncoder.encode(`${id}:${year}:${month}`);
  const hash = await crypto.subtle.digest('SHA-256', encoded);
  return arrayToB64(new Uint8Array(hash.slice(16)));
}

export default class User {
  constructor(storage, limits, info) {
    this.limits = limits;
    this.storage = storage;
    this.data = storage.user || {};
    this.info = info;
  }

  get info() {
    return this.data || this.storage.user || {};
  }

  set info(data) {
    this.data = data;
    this.storage.user = data;
  }

  get firstAction() {
    return this.storage.get('firstAction');
  }

  set firstAction(action) {
    this.storage.set('firstAction', action);
  }

  get surveyed() {
    return this.storage.get('surveyed');
  }

  set surveyed(yes) {
    this.storage.set('surveyed', yes);
  }

  get avatar() {
    const defaultAvatar = assets.get('user.svg');
    if (this.info.avatarDefault) {
      return defaultAvatar;
    }
    return this.info.avatar || defaultAvatar;
  }

  get name() {
    return this.info.name;
  }

  get email() {
    return this.info.email;
  }

  get loggedIn() {
    return this.info.loggedIn;
  }

  get maxSize() {
    return this.loggedIn
      ? this.limits.MAX_FILE_SIZE
      : this.limits.ANON.MAX_FILE_SIZE;
  }

  get maxExpireSeconds() {
    return this.loggedIn
      ? this.limits.MAX_EXPIRE_SECONDS
      : this.limits.ANON.MAX_EXPIRE_SECONDS;
  }

  get maxDownloads() {
    return this.loggedIn
      ? this.limits.MAX_DOWNLOADS
      : this.limits.ANON.MAX_DOWNLOADS;
  }

  get loginRequired() {
    // Hard coding as login will always be required for Enablo
    return true;
  }

  async metricId() {
    return this.loggedIn ? hashId(this.info.uid) : undefined;
  }

  async deviceId() {
    return this.loggedIn ? hashId(this.storage.id) : hashId(anonId);
  }

  async startAuthFlow() {
    // Removed existing fxa authentication process
  }

  async login() {
    // Removed existing fxa authentication process
    location.assign('/api/enablo/login');
  }

  async finishLogin() {
    // Removed existing fxa authentication process
  }

  async refresh() {
    // If we need to refresh, we need to reauthenticate
    location.assign('/api/enablo/login');
  }

  async logout() {
    this.storage.clearLocalFiles();
    this.info = {};
    location.assign('/api/enablo/logout');
  }

  async syncFileList() {
    let changes = { incoming: false, outgoing: false, downloadCount: false };
    if (!this.loggedIn) {
      return this.storage.merge();
    }
    let list = [];

    const key = b64ToArray(this.info.sub);
    const sha = await crypto.subtle.digest('SHA-256', key);
    const kid = arrayToB64(new Uint8Array(sha)).substring(0, 16);

    // Retry logic needs to be updated for the browser
    const retry = async () => {
      const refreshed = await this.refresh();
      if (refreshed) {
        return await this.syncFileList();
      } else {
        return { incoming: true };
      }
    };

    try {
      const encrypted = await getFileList(this.bearerToken, kid);
      const decrypted = await streamToArrayBuffer(
        decryptStream(blobStream(encrypted), key)
      );
      list = JSON.parse(textDecoder.decode(decrypted));
    } catch (e) {
      if (e.message === '401') {
        return retry(e);
      }
    }
    changes = await this.storage.merge(list);
    if (!changes.outgoing) {
      return changes;
    }
    try {
      const blob = new Blob([
        textEncoder.encode(JSON.stringify(this.storage.files))
      ]);
      const encrypted = await streamToArrayBuffer(
        encryptStream(blobStream(blob), key)
      );
      await setFileList(this.bearerToken, kid, encrypted);
    } catch (e) {
      if (e.message === '401') {
        return retry(e);
      }
    }
    return changes;
  }

  toJSON() {
    return this.info;
  }
}
